import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"

import BgBlock from "../bgBlock/BgBlock"
import ButtonText from "../buttonText/ButtonText"

import { StyledFooterGeneric, ContentFooter, TitleShare, SubtitleShare, DescriptionShare } from "./FooterGeneric.style"

const FooterGeneric = ({ className = "", classTag = "" }) => {
  const classNameID = `o-footer--generic`

  return (
    <StyledFooterGeneric className={`${classNameID}__container ${className}`}>
      <ContentFooter className={`${classNameID}__share`}>
        <BgBlock type="hideBottom" theme="dark" depth={0} heightPercentToReduce={10} className={`${classNameID}__bg-bottom`}>
          <Container>
            <Row>
              <Col xs={4} sm={8} md={12}>
                <div className={`${classNameID}__bottom`}>
                  <TitleShare>
                    COMPARTE <span>TUS JEEPSTORIES</span>
                  </TitleShare>
                  <SubtitleShare>invitación para participar</SubtitleShare>
                  <DescriptionShare>Cuéntanos desde tu primera ruta 4x4 hasta esas aventuras familiares y forma parte de nuestros 80 años de historia.</DescriptionShare>
                  <ButtonText url="/sube-tu-historia/" className={`${classNameID}__shareButton`} classTag={classTag}>
                    contar mi historia
                  </ButtonText>
                </div>
              </Col>
            </Row>
          </Container>
        </BgBlock>
      </ContentFooter>
    </StyledFooterGeneric>
  )
}

export default FooterGeneric
