import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const RotateTextStyle = styled.div`
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 720px;
  width: 100%;

  /* .pin-spacer {
    position: relative;
    display: block;
    float: left;
  } */
  .c-rotate-text__trigger {
    /* border: 1px solid red; */
    height: 45%;
    position: absolute;
    top: 10%;
    width: 10px;
  }
  .c-rotate-text__content {
    display: none;
  }
  .c-rotate-text__text {
    justify-content: flex-start;
    margin-left: 1rem;
    p {
      margin-left: -20px;
    }
  }

  @media ${device.sm} {
    top: 920px;

    .c-rotate-text__trigger {
      height: 45%;
      top: 10%;
    }
    .c-rotate-text__content {
      display: block;
    }
    .c-rotate-text__text {
      justify-content: center;
      margin-left: 0;
      width: 16.5%;
      width: calc((100% - 480px) / 2);
    }
  }
  @media ${device.md} {
    top: 780px;

    .c-rotate-text__trigger {
      height: 60%;
      top: 5%;
    }
    .c-rotate-text__content {
      display: none;
    }
    .c-rotate-text__text {
      width: calc((100% - 904px) / 2);
    }
  }
  @media ${device.lg} {
    top: 900px;

    .c-rotate-text__trigger {
      height: 75%;
      top: 0;
    }
    .c-rotate-text__content {
      display: block;
    }
    .c-rotate-text__text {
      width: calc((100% - 1093px) / 2);
    }
  }
  @media ${device.xl} {
    top: 930px;

    .c-rotate-text__trigger {
      height: 80%;
    }
    .c-rotate-text__text {
      width: calc((100% - 1440px) / 2);
    }
  }
`

export const RotateTextContainer = styled.div`
  display: inline-flex;

  p {
    color: ${props => props.theme.vars.white};
    font-family: ${props => props.theme.vars.avenirNext};
    font-size: 120px;
    font-weight: ${props => props.theme.vars.fontBlack};
    line-height: 1em;
    text-transform: uppercase;
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
`
