import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const StyledCoverContainer = styled.div`
  position: relative;
  width: 100%;

  h1 {
    color: ${props => props.theme.vars.white};
    font-family: ${props => props.theme.vars.heaters};
    font-weight: ${props => props.theme.vars.fontRegular};
    font-size: 64px;
    font-weight: normal;
    line-height: 0.8em;
  }
  h2 {
    color: ${props => props.theme.vars.white};
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 16px;
    font-weight: ${props => props.theme.vars.fontBold};
    line-height: 1em;
    margin-bottom: 25px;
    position: relative;
  }
  .c-cover--bg {
    width: 100%;
    height: 708px;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
  }
  .c-cover--texture {
    height: 710px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .c-cover--content {
    display: flex;
    flex-direction: column;
    height: 590px;
    justify-content: space-between;
    padding-bottom: 160px;
    padding-top: 80px;
    position: relative;
  }
  .c-cover--header {
    position: relative;
  }
  .c-cover--body {
    margin-bottom: 40px;
    margin-top: 40px;
    max-width: 450px;
  }
  .c-cover--footer {
    max-width: 360px;
  }
  .c-cover--promoted-big {
    display: none;
    position: absolute;
    right: -11px;
    width: 211px;

    svg {
      height: auto;
      width: 100%;
    }
  }
  .c-cover--promoted-small {
    position: absolute;
    right: 0;
    width: 45px;

    svg {
      height: auto;
      width: 100%;
    }
  }
  .c-cover--place {
    width: 100%;
    max-width: 500px;
  }
  .c-cover--badge {
    width: 85px;
  }

  @media ${device.sm} {
    h1 {
      font-size: 110px;
      margin-left: -5px;
    }
    h2 {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 18px;
      margin-bottom: 0;
      margin-right: 10%;
    }
    .c-cover--bg {
      height: 908px;
    }
    .c-cover--texture {
      height: 910px;
    }
    .c-cover--content {
      height: 765px;
      padding-bottom: 230px;
      padding-top: 130px;
    }
    .c-cover--body {
      max-width: unset;
    }
    .c-cover--footer {
      max-width: unset;
    }
    .c-cover--promoted-big {
      display: block;
    }
    .c-cover--promoted-small {
      display: none;
    }
    .c-cover--place {
      width: 110%;
      margin-left: -10%;
    }
    .c-cover--badge {
      width: 92px;
    }
  }

  @media ${device.md} {
    h1 {
      font-size: 106px;
    }
    h2 {
      margin-right: 0;
    }
    .c-cover--bg {
      height: 818px;
    }
    .c-cover--texture {
      height: 820px;
    }
    .c-cover--content {
      height: 655px;
      padding-bottom: 175px;
      padding-top: 130px;
    }
    .c-cover--badge {
      width: 95px;
    }
    .c-cover--place {
      width: 100%;
      margin-left: 0;
    }
  }

  @media ${device.lg} {
    h1 {
      font-size: 110px;
    }
    h2 {
      font-size: 20px;
    }
    .c-cover--bg {
      height: 848px;
    }
    .c-cover--texture {
      height: 850px;
    }
    .c-cover--content {
      height: 680px;
      padding-bottom: 150px;
      padding-top: 155px;
    }
    .c-cover--badge {
      width: 108px;
    }
  }
  @media ${device.xl} {
    h1 {
      font-size: 124px;
    }
    h2 {
      font-size: 22px;
    }
    .c-cover--bg {
      height: 908px;
    }
    .c-cover--texture {
      height: 910px;
    }
    .c-cover--content {
      height: 690px;
      padding-bottom: 150px;
      padding-top: 150px;
    }
    .c-cover--promoted-big {
      right: -12px;
      width: 230px;
    }
    .c-cover--badge {
      width: 145px;
    }
  }
`

export default StyledCoverContainer
