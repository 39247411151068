import React, { useState } from "react"
import { Link } from "gatsby"
import { oneOf, string, func } from "prop-types"

import { isInternal } from "../../../helpers/url"
import ButtonSocialRoll from "../buttonSocialRoll/ButtonSocialRoll"
import { IconContainer, StyledIcon, StyledLabel } from "./IconText.syle"

// To-do iconPosition

const IconText = ({ icon, label, iconPosition, url, onClick, className, classTag = "" }) => {
  const classNavItem = "e-icon-text"
  const [mouseOver, setMouseOver] = useState(false)

  const getIcon = theIcon => {
    switch (theIcon) {
      case "facebook":
        return <ButtonSocialRoll mouseOver={mouseOver} type="facebook" typeRoll="facebook_yellow" className="iconTextButton" />
      case "twitter":
        return <ButtonSocialRoll mouseOver={mouseOver} type="twitter" typeRoll="twitter_yellow" className="iconTextButton" />
      default:
        return null
    }
  }

  if (getIcon(icon) === null) return null
  if (!url) {
    return (
      <IconContainer
        className={`${className} ${classNavItem}__${iconPosition}`}
        onClick={onClick}
        onMouseEnter={() => {
          setMouseOver(true)
        }}
        onMouseLeave={() => {
          setMouseOver(false)
        }}
      >
        <StyledIcon>{getIcon(icon)}</StyledIcon>
        <StyledLabel>{label}</StyledLabel>
      </IconContainer>
    )
  }
  if (isInternal(url)) {
    return (
      <IconContainer
        className={` ${classNavItem}__${iconPosition}`}
        onMouseEnter={() => {
          setMouseOver(true)
        }}
        onMouseLeave={() => {
          setMouseOver(false)
        }}
      >
        <Link to={url} className={`${className}`}>
          <StyledIcon>{getIcon(icon)}</StyledIcon>
        </Link>
        <Link to={url} className={`${className}`}>
          <StyledLabel>{label}</StyledLabel>
        </Link>
      </IconContainer>
    )
  }
  return (
    <IconContainer
      className={`${className} ${classNavItem}__${iconPosition}`}
      onMouseEnter={() => {
        setMouseOver(true)
      }}
      onMouseLeave={() => {
        setMouseOver(false)
      }}
    >
      <a href={url} target="_blank" rel="noopener noreferrer" className={classTag}>
        <StyledIcon>{getIcon(icon)}</StyledIcon>
        <StyledLabel>{label}</StyledLabel>
      </a>
    </IconContainer>
  )
}

IconText.propTypes = {
  icon: oneOf(["facebook", "twitter"]),
  iconPosition: string,
  className: string,
  url: string,
  onClick: func,
  label: string,
}

IconText.defaultProps = {
  icon: "",
  iconPosition: "",
  className: "",
  url: null,
  onClick: () => {},
  label: "test",
}

export default IconText
