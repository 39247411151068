import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"
import NoiseWrapper from "../wrappers/NoiseWrapper"

export const StyledNoiseWrapper = styled(NoiseWrapper)`
  padding-bottom: 250px;

  @media ${device.sm} {
    padding-bottom: 30vw;
  }
  @media ${device.lg} {
    padding-bottom: 28vw;
  }
  @media ${device.xl} {
    padding-bottom: 26vw;
  }
`

export const StyledDetailContainer = styled.div`
  position: relative;
  width: 100%;

  .c-video {
    margin: 0 0 50px 0;
  }

  @media ${device.sm} {
    .c-video {
      margin: 0 0 80px 0;
      top: 0;
    }
    .c-rotate-text__text {
      min-width: 140px;
      width: calc((100% - 648px) / 2);
    }
  }

  @media ${device.md} {
    .c-video {
      margin: 0 0 60px 0;
    }
  }

  @media ${device.lg} {
    .c-video {
      margin: 30px 0 60px 0;
    }
    .c-rotate-text__text {
      width: calc((100% - 1004px) / 2);
    }
  }

  @media ${device.xl} {
    .c-video {
      margin: 80px 0;
    }
    .c-rotate-text__text {
      width: calc((100% - 1369px) / 2);
    }
  }
`
