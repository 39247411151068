import styled from "styled-components"
import { device } from "../../theme/mediaQueries"

const StoryDetailStyle = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  min-height: calc(100vh - 194px);

  .c-detail__loader {
    left: 50%;
    min-width: 300px;
    position: absolute;
    right: 50%;
    transform: translate(-50%, -50%);
  }
  .c-wrapper--noise,
  .o-footer--generic__container {
    opacity: 0;
    transition: opacity 250ms;
  }
  &.to-show {
    align-items: flex-end;
    justify-content: flex-start;

    .c-wrapper--noise,
    .o-footer--generic__container {
      opacity: 1;
    }
  }

  @media ${device.sm} {
    min-height: calc(100vh - 144px);
  }
  @media ${device.md} {
    min-height: calc(100vh - 79px);
  }

  @media ${device.xl} {
    min-height: calc(100vh - 110px);
  }
`

export default StoryDetailStyle
