import styled from "styled-components"
import bgImage from "../../../images/bg-noise.png"

const WrapperStyled = styled.div`
  display: block;
  position: relative;
  background-image: url(${bgImage});
  background-position: center;
  background-repeat: repeat;
  height: 100%;
  width: 100%;
`
export default WrapperStyled
