import styled from "styled-components"

import { device } from "../../../theme/mediaQueries"

export const StyledFooterGeneric = styled.div`
  position: relative;
  text-align: center;
  width: 100vw;
  z-index: 1;

  &.is-last {
    margin-bottom: 0;
    margin-top: 0;
    z-index: 10;
  }
  &.is-short {
    .o-footer--generic__bottom {
      padding-bottom: 120px;
    }
  }
  .o-footer--generic__top-layer-container,
  .o-footer--generic__top-layer {
    min-height: 700px;
    @media (min-width: 400px) {
      min-height: 850px;
    }
    @media (min-width: 550px) {
      min-height: 950px;
    }
    @media ${device.sm} {
      min-height: 1000px;
    }
    @media (min-width: 850px) {
      min-height: 1200px;
    }
    @media ${device.lg} {
      min-height: 1200px;
    }
    @media (min-width: 1500px) {
      min-height: 1500px;
    }
    @media (min-width: 1700px) {
      min-height: 1650px;
    }
  }
  .o-footer--generic__top-layer-container {
    position: relative;
    top: -50px;
    z-index: 2;
    @media ${device.lg} {
      top: -200px;
    }
    @media (min-width: 1700px) {
      top: -300px;
    }
  }
  .o-footer--generic__top-layer {
    align-items: center;
    display: flex;
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    visibility: hidden;
    &.is-active {
      visibility: visible;
    }
  }
  .o-footer--generic__top-layer-inner {
    padding-top: 30%;
    @media ${device.lg} {
      padding-top: 15%;
    }
    @media (min-width: 1700px) {
      padding-top: 10%;
    }
    .o-footer--generic__top {
      @media ${device.lg} {
        min-height: 35%;
      }
      @media (min-width: 1700px) {
        min-height: 40%;
      }
    }
    .o-footer--generic__jeepGrill {
      @media ${device.lg} {
        margin-bottom: 50px;
      }
      /* @media (min-width: 1700px) {
        margin-bottom: 50px;
      } */
    }
  }
  .o-footer--generic__bg-bottom {
    margin-top: -8%;
  }
  .o-footer--generic__dark-bg-container {
    position: absolute;
    margin-top: -5%;
  }
  .o-footer--generic__dark-bg {
    height: 200px;
  }
  .o-footer--generic__bg {
    left: -15%;
    height: auto;
    position: relative;
    width: 130%;
  }
  .o-footer--generic__bg-container {
    padding-top: 140px;
    position: relative;
    width: 100vw;
  }
  .o-footer--generic__top {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 35%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .o-footer--generic__jeepGrill {
    width: 74px;
    height: 22px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 36px;
  }
  .o-footer--generic__load-histories {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 60px;
    min-height: 122px;
    position: relative;
  }

  @media (min-width: 530px) {
    .o-footer--generic__bg-container {
      padding-top: 0;
    }
    .o-footer--generic__top {
      min-height: 25%;
    }
  }
  @media ${device.sm} {
    &.is-short {
      .o-footer--generic__bottom {
        padding-bottom: 150px;
      }
    }
    .o-footer--generic__dark-bg {
      height: 300px;
    }
    .o-footer--generic__jeepGrill {
      margin-bottom: 40px;
    }
    .o-footer--generic__bg {
      left: -10%;
      top: 0px;
      width: 120%;
    }
    .o-footer--generic__bg-container {
      padding-top: 20px;
    }
    .o-footer--generic__top {
      min-height: 20%;
    }
    .o-footer--generic__load-histories {
      margin-bottom: 85px;
      margin-top: 65px;
    }
  }
  @media (min-width: 850px) {
    .o-footer--generic__bg {
      left: -5%;
      width: 110%;
    }
    .o-footer--generic__bg-container {
      padding-top: 0;
    }
  }
  @media ${device.md} {
    .o-footer--generic__jeepGrill {
      width: 74px;
      height: 22px;
      margin-bottom: 60px;
    }
  }
  @media ${device.lg} {
    &.is-last {
      margin-top: -100px;
    }
    &.is-short {
      margin-top: 0;
      .o-footer--generic__bottom {
        padding-bottom: 180px;
      }
    }
    .o-footer--generic__bg-bottom {
      margin-top: -8%;
    }
    .o-footer--generic__dark-bg {
      height: 400px;
    }
    .o-footer--generic__jeepGrill {
      width: 90px;
      height: 28px;
      margin-bottom: 75px;
    }
    .o-footer--generic__bg {
      left: 0;
      width: 100%;
    }
    .o-footer--generic__top {
      min-height: 20%;
    }
    .o-footer--generic__load-histories {
      margin-bottom: 65px;
      margin-top: 65px;
    }
  }
  @media (min-width: 1700px) {
    &.is-short {
      .o-footer--generic__bottom {
        padding-bottom: 200px;
      }
    }
    .o-footer--generic__jeepGrill {
      width: 120px;
      height: 33px;
      margin-bottom: 100px;
    }
    .o-footer--generic__top {
      min-height: 25%;
    }
    .o-footer--generic__load-histories {
      margin-bottom: 200px;
      margin-top: 125px;
    }
  }
  @media ${device.xl} {
    .o-footer--generic__bg-bottom {
      margin-top: -6%;
    }
  }
`
export const ContentFooter = styled.div`
  position: relative;
  margin-bottom: -2px;

  .o-footer--generic__bottom {
    padding: 80px 0;
    @media ${device.sm} {
      padding: 50px 0 90px;
    }
    @media ${device.xl} {
      padding: 110px 0 150px;
    }
  }
`
export const TitleBlock = styled.h4`
  font-family: ${props => props.theme.vars.avenirNext};
  font-weight: ${props => props.theme.vars.fontBold};
  font-size: 34px;
  line-height: 34px;
  color: ${props => props.theme.vars.black};
  text-transform: uppercase;

  @media ${device.sm} {
    font-size: 40px;
    line-height: 44px;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${device.md} {
    font-size: 50px;
    line-height: 54px;
    max-width: 650px;
  }
  @media ${device.lg} {
    font-size: 60px;
    line-height: 64px;
    max-width: 800px;
  }
  @media (min-width: 1700px) {
    font-size: 72px;
    line-height: 76px;
    max-width: 940px;
  }
`

export const TitleShare = styled.h5`
  font-family: ${props => props.theme.vars.avenirNext};
  font-weight: ${props => props.theme.vars.fontBold};
  font-size: 30px;
  line-height: 30px;
  color: ${props => props.theme.vars.white};
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 10px;

  span {
    color: ${props => props.theme.vars.mustard};
  }

  @media ${device.sm} {
    font-size: 38px;
    margin-bottom: 40px;
  }
  @media ${device.md} {
    font-size: 48px;
    margin-bottom: 32px;
  }
  @media ${device.lg} {
    font-size: 56px;
    line-height: 48px;
  }
  @media (min-width: 1700px) {
    font-size: 72px;
    line-height: 58px;
    margin-bottom: 40px;
  }
`
export const SubtitleShare = styled.div`
  color: ${props => props.theme.vars.white};
  font-family: ${props => props.theme.vars.tradeGot};
  font-size: 14px;
  font-weight: ${props => props.theme.vars.fontBold};
  opacity: 0.8;
  margin-bottom: 32px;
  text-transform: uppercase;
  @media ${device.sm} {
    font-size: 16px;
    margin-bottom: 10px;
  }
  @media ${device.lg} {
    font-size: 18px;
  }
  @media (min-width: 1700px) {
    font-size: 24px;
  }
`
export const DescriptionShare = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.vars.white};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 56px;
  max-width: 400px;
  @media ${device.sm} {
    line-height: 18px;
    max-width: 470px;
    margin-bottom: 48px;
  }
  @media ${device.md} {
    line-height: 22px;
    max-width: 550px;
    margin-bottom: 65px;
  }
  @media ${device.lg} {
    font-size: 16px;
    line-height: 24px;
    max-width: 640px;
    margin-bottom: 54px;
  }
  @media (min-width: 1700px) {
    font-size: 18px;
    line-height: 24px;
    max-width: 720px;
    margin-bottom: 66px;
  }
`
