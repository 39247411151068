import React from "react"

import { StyledPlaceContainer, StyledIconContainer } from "./PlaceStory.style"

import SvgState from "../svgs/SvgState"

const PlaceStory = ({ state, className = "" }) => {
  const classNameId = `c-place`
  const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"]
  const today = new Date()
  const day = today.getDate()
  const month = months[today.getMonth()]
  const year = today.getFullYear()

  return (
    <StyledPlaceContainer className={className}>
      <StyledIconContainer className={`${classNameId}--icon-container`}>
        <SvgState width={42} height={42} />
      </StyledIconContainer>
      <div className={`${classNameId}--date`}>
        {day} . {month} . {year}
        <span className={`${classNameId}--divisor`} />
        {state}
      </div>
    </StyledPlaceContainer>
  )
}

export default PlaceStory
