import React, { useState, useEffect } from "react"
import IconText from "../../elements/iconText/IconText"
import { SocialContainer, Title } from "./SocialMediaDetail.style"

const SocialMediaDetail = ({ storieTitle, url, className = "" }) => {
  const classNameId = "c-social-share"
  const [shareURL, setShareURL] = useState("")

  useEffect(() => {
    if (url) {
      setShareURL(url)
    } else {
      setShareURL(window.location.href)
    }
  }, [])

  return (
    <SocialContainer className={className}>
      <Title className={`${classNameId}__title`}>Compartir</Title>
      <div className={`${classNameId}__container`}>
        <IconText icon="facebook" label="facebook" classTag="tag-historia-compartir-facebook" url={`https://facebook.com/sharer.php?display=page&u=${shareURL}`} />
        <IconText icon="twitter" label="twitter" classTag="tag-historia-compartir-twitter" url={`https://twitter.com/intent/tweet?text=${encodeURI(storieTitle)}&url=${shareURL}`} />
      </div>
    </SocialContainer>
  )
}

export default SocialMediaDetail
