import React from 'react';
import PropTypes from 'prop-types';

import LoaderSpinnerContainer, { LoaderSpin } from './LoaderSpinner.style';

const LoaderSpinner = ({ className }) => {
  const classNameID = 'o-loader-spinner';

  return (
    <LoaderSpinnerContainer className={`${classNameID} ${className}`}>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
      <LoaderSpin className={`${classNameID}__spin`}>
        <span />
      </LoaderSpin>
    </LoaderSpinnerContainer>
  );
};

LoaderSpinner.propTypes = {
  className: PropTypes.string,
};

LoaderSpinner.defaultProps = {
  className: '',
};

export default LoaderSpinner;
