import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;

  .c-social-share__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .e-icon-text__:first-child {
      margin-bottom: 25px;
    }
  }

  @media ${device.sm} {
    flex-direction: row;
    align-items: center;

    .c-social-share__title {
      width: 100%;
    }
    .c-social-share__container {
      display: flex;
      flex-direction: row;
      .e-icon-text__:first-child {
        margin-bottom: 0;
        margin-right: 30px;
      }
    }
  }
  @media ${device.md} {
    .c-social-share__container {
      .e-icon-text__:first-child {
        margin-right: 40px;
      }
    }
  }
  @media ${device.xl} {
    max-width: 520px;

    .c-social-share__container {
      .e-icon-text__:first-child {
        margin-right: 25px;
      }
    }
  }
`

export const Title = styled.p`
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fontRegular};
  margin-bottom: 30px;

  @media ${device.sm} {
    margin-bottom: 0px;
    font-size: 18px;
  }
  @media ${device.md} {
    margin-bottom: 0px;
    font-size: 18px;
  }
  @media ${device.xl} {
    font-size: 20px;
  }
`
