import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"
import NoiseWrapper from "../wrappers/NoiseWrapper"

export const StyledNoiseWrapper = styled(NoiseWrapper)`
  padding-bottom: 250px;

  @media ${device.sm} {
    padding-bottom: 30vw;
  }
  @media ${device.md} {
    padding-bottom: 28vw;
  }
  @media ${device.xl} {
    padding-bottom: 26vw;
  }
`

export const StyledDetailContainer = styled.div`
  position: relative;
  width: 100%;
`
