import styled from "styled-components";

const ScrollDragContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  will-change: transform;
  user-select: none;
  cursor: pointer;
`
export default ScrollDragContainer;