import styled from 'styled-components';
import { device } from '../../../theme/mediaQueries';

const BorderImageContainer =  styled.div`
  position: relative;
  display: inline-block;
  width: 262px;
  margin-bottom: 5px;
  margin-right: 10px;
  z-index:1;

  .c-border--image__hashtag{
    font-family: ${props =>  props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    color: ${props => props.theme.vars.charade};
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-left: 17px;
    margin-bottom: 10px;
  }

  .c-border--image__container{
    height: 300px;
    border-radius: 15px;
    background-color: ${props => props.theme.vars.white};
    -webkit-box-shadow: 0px 2px 0px 1px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 2px 0px 1px rgba(0,0,0,0.10);
    box-shadow: 0px 2px 0px 1px rgba(0,0,0,0.10);
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 13px;
    box-sizing: border-box;
  }

  @media ${device.sm} {
    width: 312px;
    margin-right: 22px;

    .c-border--image__container{
      height: 400px;
    }
  }

  @media ${device.md} {
    width: 400px;
    margin-bottom: 40px;

    .c-border--image__hashtag{
      margin-bottom: 16px;
    }

    .c-border--image__container{
      height: 502px;
    }

    img{
      padding: 18px;
    }
  }

  @media ${device.lg} {
    width: 100%;
    margin-bottom: 72px;

    .c-border--image__container{
      height: 582px;
    }

    img{
      padding: 22px;
    }
  }

  @media ${device.xl} {
    .c-border--image__container{
      height: 775px;
    }

    .c-border--image__hashtag{
      margin-bottom: 28px;
    }

     img{
       padding: 30px;
     }
  }
`
export default BorderImageContainer;