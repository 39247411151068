import React from "react"
import { withPrefix } from "gatsby"
import { Container, Row, Col } from "react-awesome-styled-grid"

import getImgixUrl from "../../../helpers/imgix"

import useBreakpointWidth from "../../../hooks/useBreakpointWidth"
import CoverDetail from "../coverDetail/CoverDetail"
import StoryDescText from "../../elements/storyDescText/StoryDescText"
import BorderImage from "../../elements/borderImage/BorderImage"
import ScrollDrag from "../scrollDrag/ScrollDrag"
import SocialMediaDetail from "../socialMediaDetail/SocialMediaDetail"
import RotateTextAnimated from "../../elements/rotateText/RotateTextAnimated"
import { StyledNoiseWrapper, StyledDetailContainer } from "./DetailTypeImages.style"

const DetailTypeImages = ({ storie }) => {
  const classNameId = `c-detail--images`
  const size = useBreakpointWidth()

  return (
    <StyledNoiseWrapper>
      <StyledDetailContainer>
        {size.min >= 1360 ? <RotateTextAnimated className={`${classNameId}__rotateText`} /> : null}
        <CoverDetail className={`${classNameId}__cover`} title={storie.titulo} name={storie.autor} urlImage={getImgixUrl(storie.imagen1)} state={storie.estado} isPromoted={storie.etiquetas && storie.etiquetas.indexOf("destacadas") !== -1} />
        <div className={`${classNameId}__content`}>
          {size.min < 1024 ? (
            <>
              <ScrollDrag className={`${classNameId}__scrollHorizontal`}>
                {storie.imagen2 && <BorderImage src={getImgixUrl(storie.imagen2)} alt={storie.autor} />}
                {storie.imagen3 && <BorderImage src={getImgixUrl(storie.imagen3)} alt={storie.autor} />}
                {storie.imagen4 && <BorderImage src={getImgixUrl(storie.imagen4)} alt={storie.autor} />}
                {storie.imagen5 && <BorderImage src={getImgixUrl(storie.imagen5)} alt={storie.autor} />}
              </ScrollDrag>
              <Container>
                <Row>
                  <Col xs={4} sm={6} md={6} lg={6} xl={6} offset={{ sm: 1 }}>
                    <StoryDescText description={storie.descripcion} />
                    <SocialMediaDetail className={`${classNameId}__share`} storieTitle={storie.titulo} />
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <Container>
              <Row>
                <Col md={6}>
                  {storie.imagen2 && <BorderImage src={getImgixUrl(storie.imagen2)} alt={storie.autor} />}
                  {storie.imagen3 && <BorderImage src={getImgixUrl(storie.imagen3)} alt={storie.autor} />}
                  {storie.imagen4 && <BorderImage src={getImgixUrl(storie.imagen4)} alt={storie.autor} />}
                  {storie.imagen5 && <BorderImage src={getImgixUrl(storie.imagen5)} alt={storie.autor} />}
                </Col>
                <Col md={6} lg={5} xl={5} offset={{ lg: 1, xl: 1 }}>
                  <StoryDescText className={`${classNameId}__description`} description={storie.descripcion} />
                  <SocialMediaDetail className={`${classNameId}__share`} storieTitle={storie.titulo} url={`${process.env.GATSBY_SITE_URL}${withPrefix("/")}share/index.php?id=${storie.id}`} />
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </StyledDetailContainer>
    </StyledNoiseWrapper>
  )
}

export default DetailTypeImages
