import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const LoaderHistoriesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  .o-loader-histories__text {
    color: ${props => props.theme.vars.charade};
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    letter-spacing: 2px;
    line-height: 1em;
    margin-bottom: 26px;
    text-transform: uppercase;
  }

  @media ${device.sm} {
    .o-loader-histories__text {
      margin-bottom: 28px;
    }
  }

  @media ${device.md} {
    .o-loader-histories__text {
      margin-bottom: 36px;
    }
  }

  @media ${device.lg} {
    .o-loader-histories__text {
      font-size: 16px;
    }
  }
`

export default LoaderHistoriesContainer
