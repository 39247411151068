import React, { useEffect, useState } from "react"

import { getStorieData } from "../../api/api"
import LoaderHistories from "../elements/loaders/LoaderHistories"
import DetailTypeImages from "../modules/detailTypeImages/DetailTypeImages"
import DetailTypeVideo from "../modules/detailTypeVideo/DetailTypeVideo"
import DetailTypeAudio from "../modules/detailTypeAudio/DetailTypeAudio"
import FooterGeneric from "../elements/footerGeneric/FooterGeneric"
import StoryDetailStyle from "./StoryDetail.style"

const StoryDetail = ({ id }) => {
  const [storieData, setStorieData] = useState("")
  const [showHistory, setShowStory] = useState(false)

  const fetchData = async () => {
    const data = await getStorieData(id)
    if (!data[0]) {
      window.location.href = "/404"
    } else {
      setStorieData(data[0])
    }
  }

  const showTypeStory = type => {
    switch (type) {
      case "imagen":
        return <DetailTypeImages storie={storieData} />
      case "video":
        return <DetailTypeVideo storie={storieData} />
      case "audio":
        return <DetailTypeAudio storie={storieData} />
      default:
        return null
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowStory(true)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [storieData])

  return (
    <StoryDetailStyle className={`c-detail__content ${showHistory ? "to-show" : ""}`}>
      {storieData && showTypeStory(storieData.tipo)}
      {!showHistory && <LoaderHistories label="Cargando historia" className="c-detail__loader" />}
      <FooterGeneric classTag="tag-historia-contar" />
    </StoryDetailStyle>
  )
}

export default StoryDetail
