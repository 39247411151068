import React, { useEffect } from 'react';
import { Router, useMatch } from '@reach/router';
import StoryDetail from '../components/template/StoryDetail';

const Stories = () => {
  const match = useMatch('/historias/:id');

  useEffect(() => {
    if (match === null) window.location.href = '/';
  }, []);

  return (
    <div className="wrapper">
      <Router>
        <StoryDetail path="/historias/:id" />
      </Router>
    </div>
  );
};

export default Stories;
