import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"
import NoiseWrapper from "../wrappers/NoiseWrapper"

export const StyledNoiseWrapper = styled(NoiseWrapper)`
  display: block;
  padding-bottom: 250px;
  position: relative;

  @media ${device.sm} {
    padding-bottom: 30vw;
  }
  @media ${device.md} {
    padding-bottom: 28vw;
  }
  @media ${device.xl} {
    padding-bottom: 26vw;
  }
`

export const StyledDetailContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;

  .c-detail--images__content {
    position: relative;
    z-index: 1;
  }
  .c-detail--images__scrollHorizontal {
    margin-bottom: 30px;
    z-index: 1;
    > div:first-child {
      margin-left: 10px;
    }
  }
  .c-detail--images__trigger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 70vh;

    @media ${device.md} {
      height: 100vh;
    }
  }
  @media ${device.sm} {
    .c-detail--images__bg {
      z-index: 1;
    }
    .c-detail--images__scrollHorizontal {
      margin-bottom: 65px;
      > div:first-child {
        margin-left: 60px;
      }
    }
  }
  @media ${device.md} {
    .c-detail--images__description {
      padding-top: 40px;
    }
  }
  @media ${device.lg} {
    .c-detail--images__description {
      padding-top: 45px;
    }
  }
  @media ${device.xl} {
    .c-detail--images__description {
      padding-top: 55px;
    }
    .c-detail--images__share {
      max-width: 590px;
    }
  }
`
