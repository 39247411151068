import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const TextContainer = styled.div`
  padding: 0px 0px 48px 0px;
  font-family: ${props => props.theme.vars.draftNatural};
  font-size: 14px;
  font-weight: ${props => props.theme.vars.fontMedium};
  color: ${props => props.theme.vars.black};
  position: relative;
  margin-bottom: 50px;
  line-height: 1.8em;
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.vars.grayNickel};
    opacity: 0.5;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  @media ${device.sm} {
    font-size: 18px;
    padding: 0px 0px 53px 0px;

    :after {
      content: "";
      position: absolute;
      bottom: 0;
      width: calc(100% + 30px);
      height: 2px;
      background-color: ${props => props.theme.vars.grayNickel};
      opacity: 0.5;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }
  @media ${device.md} {
    font-size: 19px;
    :after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: ${props => props.theme.vars.grayNickel};
      opacity: 0.5;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }
  @media ${device.lg} {
    padding: 0px 0px 85px 0px;
    :after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: ${props => props.theme.vars.grayNickel};
      opacity: 0.5;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }
`

export default TextContainer
