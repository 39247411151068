import React from "react"
import { withPrefix } from "gatsby"
import { Container, Col, Row } from "react-awesome-styled-grid"

import getImgixUrl from "../../../helpers/imgix"

import useBreakpointWidth from "../../../hooks/useBreakpointWidth"
import CoverDetail from "../coverDetail/CoverDetail"
import StoryDescText from "../../elements/storyDescText/StoryDescText"
import SocialMediaDetail from "../socialMediaDetail/SocialMediaDetail"
import AudioItemDetail from "../../elements/audioItem/AudioItemDetail"
import RotateTextAnimated from "../../elements/rotateText/RotateTextAnimated"
import { StyledNoiseWrapper, StyledDetailContainer } from "./DetailTypeAudio.style"

const DetailTypeAudio = ({ storie }) => {
  const classNameId = `c-detail--audio`
  const size = useBreakpointWidth()

  return (
    <StyledNoiseWrapper>
      <StyledDetailContainer>
        {size.min >= 1360 ? <RotateTextAnimated className={`${classNameId}__rotateText`} /> : null}
        <CoverDetail title={storie.titulo} name={storie.autor} urlImage={getImgixUrl(storie.imagen1)} state={storie.estado} isPromoted={storie.etiquetas && storie.etiquetas.indexOf("destacadas") !== -1} />
        <AudioItemDetail audioLink={storie.audioitem} />
        <Container>
          <Row>
            <Col sm={6} md={10} lg={8} xl={8} offset={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
              <StoryDescText description={storie.descripcion} />
              <SocialMediaDetail storieTitle={storie.titulo} url={`${process.env.GATSBY_SITE_URL}${withPrefix("/")}share/index.php?id=${storie.id}`} />
            </Col>
          </Row>
        </Container>
      </StyledDetailContainer>
    </StyledNoiseWrapper>
  )
}

export default DetailTypeAudio
