import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const IconContainer = styled.div`
  align-items: center;
  display: inline-flex;

  a {
    align-items: center;
    display: flex;
  }
`

export const StyledIcon = styled.div`
  display: block;
  margin-right: 20px;
  pointer-events: none;

  svg {
    width: 41px;
    height: 41px;
  }
  .o-buttonsocial__svgbgnormal {
    background-color: ${props => props.theme.vars.outerSpace};
  }
  .o-buttonsocial__svgbgroll {
    background-color: ${props => props.theme.vars.outerSpace};
  }
  @media ${device.sm} {
    margin-right: 14px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media ${device.xl} {
    svg {
      width: 41px;
      height: 41px;
    }
  }
`
export const StyledLabel = styled.span`
  pointer-events: none;
  font-size: 12px;
  font-weight: ${props => props.theme.vars.fontBold};
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  @media ${device.xl} {
    font-size: 14px;
  }
`
