import React from "react"

import BorderImageContainer from "./BorderImage.style"

const classNameId = `c-border--image`

const BorderImage = ({ src, alt = "", _className = "" }) => {
  return (
    <BorderImageContainer className={_className}>
      <div className={`${classNameId}__hashtag`}>#jeepstories</div>
      <div className={`${classNameId}__container`}>
        <img src={src} alt={alt} />
      </div>
    </BorderImageContainer>
  )
}

export default BorderImage
