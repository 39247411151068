import React, { useState, useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
// import PropTypes from 'prop-types';

// import AudioCanvas from './AudioCanvas';
import p5 from "p5"
import "p5/lib/addons/p5.sound"

import SvgPlay from "../svgs/SvgPlay"
import SvgPause from "../svgs/SvgPause"
import useBreakpointWidth from "../../../hooks/useBreakpointWidth"

import ProgressRing from "../progressRing/ProgressRing"

import { StyledAudioItem } from "./AudioItem.style"

const AudioItemDetail = ({ audioLink, className }) => {
  const classNameID = `o-audioitem-detail`
  const size = useBreakpointWidth()
  const [isLoaded, setIsLoaded] = useState(false)
  const [canvasWidth, setCanvasWidth] = useState(0)
  const [canvasHeight, setCanvasHeight] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)

  const [soundFile, setSoundFile] = useState(null)
  const [timer, setTimer] = useState("00:00")
  const [totalDuration, setTotalDuration] = useState(0)
  const [progress, setProgress] = useState(0)
  const [audioVisible, setAudioVisible] = useState(false)
  // const [spectrum,setSpectrum] = useState(null)
  const [drawTimeout, setDrawTimeout] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [FFT, setFFT] = useState(new p5.FFT())
  const canvas = useRef()
  const canvasContainer = useRef()
  const ringContainer = useRef()

  function parseMillisecondsIntoReadableTime(milliseconds) {
    const hours = milliseconds / (1000 * 60 * 60)
    const absoluteHours = Math.floor(hours)
    // const h = absoluteHours > 9 ? absoluteHours : `0${absoluteHours}`

    const minutes = (hours - absoluteHours) * 60
    const absoluteMinutes = Math.floor(minutes)
    const m = absoluteMinutes > 9 ? absoluteMinutes : `0${absoluteMinutes}`

    const seconds = (minutes - absoluteMinutes) * 60
    const absoluteSeconds = Math.floor(seconds)
    const s = absoluteSeconds > 9 ? absoluteSeconds : `0${absoluteSeconds}`

    return `${m}:${s}`
  }
  function stopDraw() {
    clearInterval(drawTimeout)
  }

  useEffect(() => {
    const sf = new p5.SoundFile(audioLink, () => {
      setIsLoaded(true)
      setSoundFile(sf)

      setTimer(parseMillisecondsIntoReadableTime(sf.duration()))
      setTotalDuration(sf.duration())
      FFT.setInput(sf)
    })

    canvas.current.height = canvasContainer.current.clientHeight
    canvas.current.width = canvasContainer.current.clientWidth
    setCanvasWidth(canvasContainer.current.clientWidth)
    setCanvasHeight(canvasContainer.current.clientHeight)
  }, [])

  useEffect(() => {
    if (soundFile === undefined || soundFile === null) return
    soundFile.onended(() => {
      stopDraw()

      if (isPlaying) {
        setProgress(100)
      }
      setIsPlaying(false)
    })
  }, [soundFile])
  function pause() {
    stopDraw()
    setIsPlaying(false)
    soundFile.pause()
  }
  function setSpectrum(sprectrum) {
    const ctx = canvas.current.getContext("2d")
    ctx.fillStyle = "#F4D15D"

    const wavespace = canvasWidth / sprectrum.length
    ctx.clearRect(0, 0, canvasWidth, canvasHeight)
    for (let i = 0; i < sprectrum.length; i += 1) {
      const leheight = (sprectrum[i] * canvasHeight) / 256
      const lety = (canvasHeight - leheight) / 2
      ctx.fillRect(i * wavespace, lety, 1, leheight)
    }
  }
  function play() {
    setAudioVisible(true)
    soundFile.play()

    setIsPlaying(true)

    setDrawTimeout(
      setInterval(() => {
        const spectrum = FFT.analyze()
        setProgress((soundFile.currentTime() * 100) / totalDuration)
        setTimer(parseMillisecondsIntoReadableTime(soundFile.currentTime() * 1000))
        setSpectrum(spectrum)
      }, 100)
    )
  }
  function togglePlay() {
    if (!isLoaded) return false
    if (isPlaying) {
      pause()
    } else {
      play()
    }
    return null
  }

  return (
    <StyledAudioItem className={`${classNameID} ${className} `}>
      <div className={`${classNameID}__container `}>
        <div className={`${classNameID}__canvascontainer is-detail`} ref={canvasContainer}>
          {!audioVisible ? <StaticImage className="o-audio__placeholder" src="../../../images/sound/jeep_80_aduio_espectro.png" alt="Jeep Audio" placeholder="none" objectFit="cover" /> : null}
          <canvas className={`${classNameID}__canvas`} ref={canvas} />
        </div>
        <div className={`${classNameID}__controls `}>
          <div className={`${classNameID}__button`} ref={ringContainer} onClick={togglePlay} role="button" tabIndex={0} onKeyPress={null}>
            {size.min < 768 && <ProgressRing ref={canvas} className={`${classNameID}__progressring`} radius={57} stroke={4} progress={progress} />}
            {size.min < 1024 && size.min >= 768 && <ProgressRing ref={canvas} className={`${classNameID}__progressring`} radius={80} stroke={4} progress={progress} />}
            {size.min < 1024 && size.min >= 768 && <ProgressRing ref={canvas} className={`${classNameID}__progressring`} radius={80} stroke={4} progress={progress} />}
            {size.min < 1360 && size.min >= 1024 && <ProgressRing ref={canvas} className={`${classNameID}__progressring`} radius={85} stroke={4} progress={progress} />}
            {size.min < 1920 && size.min >= 1360 && <ProgressRing ref={canvas} className={`${classNameID}__progressring`} radius={85} stroke={4} progress={progress} />}
            {size.min >= 1920 && <ProgressRing ref={canvas} className={`${classNameID}__progressring`} radius={100} stroke={4} progress={progress} />}
            {isLoaded ? (
              <div className={`${classNameID}__playpausecontainer`}>
                {!isPlaying ? (
                  <div className={`${classNameID}__play`}>
                    <SvgPlay />
                  </div>
                ) : (
                  <div className={`${classNameID}__pause`}>
                    <SvgPause />
                  </div>
                )}
              </div>
            ) : null}

            <div className={`${classNameID}__timer`}>{timer}</div>
          </div>
        </div>
      </div>
    </StyledAudioItem>
  )
}

/* AudioItemDetail.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  align: PropTypes.oneOf(['LEFT', 'RIGHT']),
  checked: PropTypes.bool,
};

AudioItemDetail.defaultProps = {
  value: '',
  label: '',
  onClick: () => {},
  className: '',
  align: CheckboxAlign.left,
  
}; */

export default AudioItemDetail
