import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const StyledPlaceContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: calc(100vw - 20px);
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid ${props => props.theme.vars.charade};

  &.is-yellow {
    border: 2px solid ${props => props.theme.vars.creamCan};

    .c-place--icon-container {
      background-color: ${props => props.theme.vars.creamCan};
      svg {
        fill: ${props => props.theme.vars.charade};
      }
    }
    .c-place--date {
      color: ${props => props.theme.vars.white};
    }
    .c-place--divisor {
      background-color: ${props => props.theme.vars.white};
    }
  }

  .c-place--date {
    color: ${props => props.theme.vars.charade};
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 10px;
    font-weight: ${props => props.theme.vars.fontBold};
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    width: calc(100% - 60px);
  }
  .c-place--divisor {
    display: inline-block;
    margin: 0px 10px;
    width: 1px;
    height: 8px;
    background-color: ${props => props.theme.vars.charade};
  }

  @media ${device.sm} {
    width: 100%;
    left: auto;
    transform: none;

    .c-place--date {
      font-size: 12px;
    }
  }

  @media ${device.md} {
    border: 2px solid ${props => props.theme.vars.creamCan};

    .c-place--date {
      font-size: 13px;
      color: ${props => props.theme.vars.white};
    }
    .c-place--divisor {
      background-color: ${props => props.theme.vars.white};
    }
  }

  @media ${device.lg} {
    height: 65px;
    width: 400px;
    margin: 0px auto;

    .c-place--date {
      font-size: 14px;
      width: calc(100% - 65px);
    }
    .c-place--divisor {
      margin: 0px 24px;
      width: 2px;
      height: 10px;
    }
  }

  @media ${device.xl} {
    height: 80px;
    width: 540px;

    .c-place--date {
      font-size: 16px;
      width: calc(100% - 90px);
    }
    .c-place--divisor {
      height: 12px;
    }
  }
`

export const StyledIconContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.vars.charade};
  display: flex;
  justify-content: center;
  height: 100%;
  width: 60px;

  svg {
    fill: ${props => props.theme.vars.white};
    height: 25px;
    width: 25px;
  }

  @media ${device.md} {
    background-color: ${props => props.theme.vars.creamCan};

    svg {
      fill: ${props => props.theme.vars.charade};
    }
  }

  @media ${device.lg} {
    width: 65px;

    svg {
      height: 42px;
      width: 42px;
    }
  }

  @media ${device.xl} {
    width: 90px;
  }
`
