import React from "react"
import PropTypes from "prop-types"

import LoaderSpinner from "./LoaderSpinner"
import LoaderHistoriesContainer from "./LoaderHistories.style"

const LoaderHistories = ({ label, className }) => {
  const classNameID = "o-loader-histories"

  return (
    <LoaderHistoriesContainer className={`${classNameID} ${className}`}>
      <p className={`${classNameID}__text`}>{label}</p>
      <LoaderSpinner />
    </LoaderHistoriesContainer>
  )
}

LoaderHistories.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

LoaderHistories.defaultProps = {
  label: "Cargando historias",
  className: "",
}

export default LoaderHistories
