import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { isIOS } from "../../../helpers/platform"
import useWindowSize from "../../../hooks/useWindowSize"
import RotateText from "./RotateText"
import { RotateTextStyle } from "./RotateText.style"

const RotateTextAnimated = ({ className = "" }) => {
  const classNameId = `c-rotate-text`
  const windowSize = useWindowSize()
  const baseTrigger = useRef(null)
  const tl = useRef(null)

  const setAnimations = () => {
    gsap.utils.toArray(`.${classNameId} .item-parallax`).forEach(layer => {
      const movement = layer.offsetHeight * 0.2
      gsap.set(layer, { y: 0 })
      tl.current.fromTo(layer, { y: 0 }, { y: -movement, ease: "none" }, 0)
    })
  }

  const initAnimations = () => {
    if (!isIOS()) {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: baseTrigger.current,
          start: "20% bottom",
          end: "bottom top",
          scrub: 0.5,
          toggleClass: "is-active",
          // markers: true,
          // id: 'rotateText',
        },
      })
      setAnimations()
    }
  }

  useEffect(() => {
    initAnimations()

    return () => {
      if (tl.current) {
        tl.current.clear()
        tl.current.kill()
        tl.current = null
      }
    }
  }, [])

  useEffect(() => {
    let timer = 0
    if (windowSize.width !== 0) {
      tl.current.clear()
      setAnimations()
      ScrollTrigger.refresh()
      timer = setTimeout(() => {
        ScrollTrigger.refresh()
      }, 300)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [windowSize.width])

  return (
    <RotateTextStyle className={`${classNameId} ${className}`}>
      <div className={`${classNameId}__trigger`} ref={baseTrigger} />
      <div className={`${classNameId}__content item-parallax`}>
        <RotateText className={`${classNameId}__text`}>
          <p>jeepstories / jeepstories / jeepstories / jeepstories / jeepstories / jeepstories / jeepstories</p>
        </RotateText>
      </div>
    </RotateTextStyle>
  )
}

export default RotateTextAnimated
