import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Picture } from "react-responsive-picture"
import { Container, Row, Col } from "react-awesome-styled-grid"

import PlaceStory from "../../elements/placeStory/PlaceStory"
import SvgPromoted from "../../elements/svgs/SvgPromoted"
import SvgPromotedSmall from "../../elements/svgs/SvgPromotedSmall"
import backgroundMobile from "../../../images/forms/jeep-formulario-textura_320.png"
import backgroundTablet from "../../../images/forms/jeep-formulario-textura_768.png"
import background1024 from "../../../images/forms/jeep-formulario-textura_1024.png"
import background1366 from "../../../images/forms/jeep-formulario-textura_1366.png"
import background1920 from "../../../images/forms/jeep-formulario-textura_1920.png"
import StyledCoverContainer from "./CoverDetail.style"

const CoverDetail = ({ title, name, urlImage = "", state = "", className = "", isPromoted = false }) => {
  const classNameId = `c-cover`

  return (
    <StyledCoverContainer className={className}>
      <img className={`${classNameId}--bg`} src={urlImage} alt={name} />
      <Picture
        className="c-cover--texture"
        sources={[
          {
            srcSet: backgroundMobile,
            media: "(max-width: 767px)",
          },
          {
            srcSet: backgroundTablet,
            media: "(max-width: 1023px)",
          },
          {
            srcSet: background1024,
            media: "(max-width: 1360px)",
          },
          {
            srcSet: background1366,
            media: "(max-width: 1919px)",
          },
          {
            srcSet: background1920,
            media: "(min-width: 1920px)",
          },
        ]}
      />
      <div className={`${classNameId}--content`}>
        <div className={`${classNameId}--header`}>
          {isPromoted && (
            <div className={`${classNameId}--promoted`}>
              <SvgPromoted className={`${classNameId}--promoted-big`} />
              <SvgPromotedSmall className={`${classNameId}--promoted-small`} />
            </div>
          )}
          <Container>
            <Row>
              <Col md={10} lg={10} xl={10}>
                <StaticImage className={`${classNameId}--badge`} src="../../../images/jeep-aniversario-logo.png" alt="Jeep Aniversario 80" placeholder="none" objectFit="contain" />
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`${classNameId}--body`}>
          <Container>
            <Row>
              <Col sm={7} md={8} lg={7} xl={8}>
                <h1>{title}</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`${classNameId}--footer`}>
          <Container>
            <Row>
              <Col sm={4} md={5} lg={4} xl={4}>
                <h2>{name}</h2>
              </Col>
              <Col sm={4} md={5} lg={5} xl={5}>
                <PlaceStory state={state} className={`${classNameId}--place is-yellow`} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </StyledCoverContainer>
  )
}

export default CoverDetail
